:root {
  --font_poppins: 'Poppins', sans-serif;
  --font_oswald: 'Oswald', sans-serif;

  --c_white: #fff;
  --c_black: #000;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Bebas Neue', cursive;
  font-weight: normal;
}

p, span, strong, ul li {
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
}



